<template>
  <!-- 未结订单 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select v-model="form.vendorId" value-key="id" multiple clearable filterable collapse-tags>
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select v-model="form.style" value-key="id" clearable filterable multiple collapse-tags>
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="BU名称" :class="$i18n.locale">
              <el-input v-model="form.operatorGroup" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="Operator" :class="$i18n.locale">
              <el-input v-model="form.operatorUser" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <ImportButton export-key="scm_order_delay" :params="queryParameter" />
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="purchaseOrderCode" label="采购订单" min-width="160" align="center" sortable />
      <el-table-column prop="vendorName" label="供应商" width="160" align="center" sortable />
      <el-table-column prop="purchasePersonnel" label="采购员" width="100" align="center" sortable />
      <el-table-column prop="style" label="style" width="130" align="center" sortable />
      <el-table-column prop="operatorGroupUser" label="BU-Operator" width="130" align="center" sortable />
      <el-table-column prop="unclearedNumber" label="未结数量" width="140" align="center" sortable />
      <el-table-column prop="lastDelivery" label="原采购反馈出货时间" width="170" align="center" sortable />
      <el-table-column prop="currDelivery" label="现采购反馈出货时间" width="170" align="center" sortable />
      <el-table-column label="延期或提前天数" width="170" align="center" sortable>
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="对比原采购反馈出货时间" placement="top">
            <span>{{ scope.row.daysLastDelivery }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="planDeliveryDate" label="计划评审时间" width="140" align="center" sortable />
      <el-table-column prop="daysByPlanDelivery" label="延期或提前天数" width="170" align="center" sortable>
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="对比计划评审时间" placement="top">
            <span>{{ scope.row.daysByPlanDelivery }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        :page-sizes="[10, 50, 200, 500]"
        @pagination="_unclearedOrderPage"
      />
    </div>
  </div>
</template>
<script>
import { queryVendorList, homeOrderDelayPage, queryStyleList } from '@/api/scm-api'
import Pagination from '@/components/Pagination'
import ImportButton from '@/components/ExportFile'
export default {
  components: { Pagination, ImportButton },
  data() {
    return {
      Vendordatas: [],
      addVisible: false,
      addLoading: false,
      editRow: {},
      companyOptions: [],
      vendorOptions: [],
      TableLoading: false,
      showRow: false, // 切换显示input
      tableDatas: [],
      styleOptions: [],
      PlatFormAndSiteData: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {
        vendorId: [],
        style: []
      }
    }
  },
  computed: {
    queryParameter() {
      const { vendorId, style } = this.form
      return Object.assign({}, this.form, this.pager, { vendorId: vendorId?.toString(), style: style?.toString() })
    }
  },
  mounted() {
    this.queryClick()
    this._queryStyleList()
    this._queryVendorList()
  },
  methods: {
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._unclearedOrderPage()
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.queryClick()
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 默认查询
    async _unclearedOrderPage() {
      try {
        this.TableLoading = true
        const { datas } = await homeOrderDelayPage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scope>
.el-tooltip__popper {
  max-width: 20%;
}
.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  background: #e6e6e6 !important;
  color: #303133 !important;
}
</style>
